<template>
  <div class="mt-20">
    <AppTable
      :dataset="categoriesList"
      :headers="tableHeaders"
      class="categories-table"
      rowHeight="50px"
      type="admin"
      theme="primary"
      show-actions
      @sort-by="handleSort"
    >
      <!-- R O W S -->

      <template #name="{ row }">
        <div class="flex items-center">
          <div class="flex items-center justify-center w-30 h-30 bg-primary rounded-full mr-15">
            <AppIcon
              :name="row.iconName"
              size="20"
              class="text-white"
            />
          </div>
          <p class="text-dark-cl-20">{{ row.name }}</p>
        </div>
      </template>

      <!-- A C T I O N S -->
      <template #actions="{ row, handleHidePopper }">
        <ul class="actions-wrapper py-10">
          <template
            v-for="(action, i) in actions"
            :key="i"
          >
            <li
              v-if="!action.hidden"
              class="action flex items-center cursor-pointer py-7 px-10"
              :class="action.customClass"
              @click="action.handler(row, handleHidePopper)"
            >
              <AppIcon
                :name="action.icon"
                size="12"
                class="mr-10"
              />
              <span>{{ action.title }}</span>
            </li>
          </template>
        </ul>
      </template>
    </AppTable>

    <!-- P A G I N A T I O N -->
    <div
      v-if="categoriesList.length"
      class="flex justify-between items-center pt-20 border-t border-grey-fp-10"
    >
      <PaginationCounter
        :offset="offset"
        :limit="CATEGORIES_PAGINATION_LIMIT"
        :total="schoolsTotal"
        class="text-posey-blue"
      />

      <v-pagination
        v-model:current-page="currentPage"
        :page-size="CATEGORIES_PAGINATION_LIMIT"
        layout="prev, pager, next"
        hide-on-single-page
        :total="schoolsTotal"
        @current-change="onCurrentPageChanged"
      />
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent, computed, ref, onBeforeMount, reactive, toRefs, watch } from 'vue';
  import { useToast } from "vue-toastification";

  import AppIcon from '@/components/stateless/AppIcon.vue';
  import AppTable from '@/components/stateless/AppTable.vue';
  import PaginationCounter from '@/components/PaginationCounter.vue';

  import { routesNames, router } from '@/router';
  import { ICategory, ICategoryRes } from '@/types';
  import { vuex } from '@/store';
  import { categoriesService } from '@/services';
  import { CATEGORIES_PAGINATION_LIMIT, categoriesParams } from '../api-params';

  export default defineComponent({
    name: 'CategoriesList',

    components: { AppIcon, AppTable, PaginationCounter },

    props: {
      archived: {
        type: Boolean,
        default: false
      }
    },

    emits: ['edit', 'handle-archive'],

    setup(props, { emit }) {
      const toast = useToast();
      const state = reactive({
        categoriesList: [] as ICategory[],
        schoolsTotal: 0 as number,
        sortListBy: [] as Array<string[]>,
      });
      let isOpenConfirmDeleteModal = ref<boolean>(false);
      const currentPage = ref<number>(1);
      const skipRefetchCategories = ref<boolean>(false); // needed for avoid removing and pagination

      const { categoriesList, schoolsTotal } = toRefs(state);
      const { archived } = toRefs(props);

      const tableHeaders = computed(() => [
        { property: 'name', label: 'Category', sortable: true },
      ]);

      const offset = computed<number>(() => {
        return (currentPage.value - 1) * CATEGORIES_PAGINATION_LIMIT;
      });

      const actions = computed(() => ([
        { title: 'Edit', icon: 'pen', handler: onEdit, customClass: 'text-dark-cl-20', hidden: archived.value },
        {
          title: archived.value ? 'Unarchive' : 'Archive',
          icon: archived.value ? 'pen' : 'trash-alt',
          handler: handleArchive,
          customClass: archived.value ? 'text-dark-cl-20' : 'text-error'
        },
      ]));


      watch(() => archived.value, () => fetchCategories());

      function onCurrentPageChanged() {
        if (!skipRefetchCategories.value) {
          fetchCategories();
        }
      }

      function handleSort({ orderBy, order }: any) {
        // Set appropriate values to order property
        if (order) {
          state.sortListBy = [[orderBy, order]];
        } else {
          state.sortListBy = [];
        }

        fetchCategories();
      }

      function handleArchive({ id }: ICategory, hideTooltipCb: any) {
        emit('handle-archive', id);
        skipRefetchCategories.value = true;
        hideTooltipCb();
      }

      function onEdit(payload: ICategory, hideTooltipCb: any) {
        emit('edit', { name: payload.name, id: payload.id, iconName: payload.iconName });
        hideTooltipCb();
      }

      async function fetchCategories() {

        vuex.setLoading(true);

        return await categoriesService.fetchCategories(categoriesParams(
          offset.value,
          state.sortListBy,
          archived.value ? 'archived' : 'active'
        ))
          .then((res: ICategoryRes) => {
            categoriesList.value = res.data;
            schoolsTotal.value = res.totalCount;
            skipRefetchCategories.value = false;
          })
          .catch(({ response }: any) => {
            const { data } = response;
            toast.error(data.message);
          })
          .finally(() => vuex.setLoading(false));
      }

      onBeforeMount(() => {
        fetchCategories();
      });

      return {
        categoriesList,
        routesNames,
        router,
        tableHeaders,
        actions,
        isOpenConfirmDeleteModal,
        offset,
        schoolsTotal,
        currentPage,

        CATEGORIES_PAGINATION_LIMIT,

        onCurrentPageChanged,
        fetchCategories,
        handleSort
      };
    }

  });
</script>

<style lang="scss" scoped>
  .categories-table {
    max-height: 455px;
  }
</style>