
  import { defineComponent, ref, reactive, toRefs, computed, nextTick } from 'vue';
  import { useToast } from "vue-toastification";
  import { useVuelidate } from "@vuelidate/core";
  import { isEqual } from 'lodash';

  import AppButton from '@/components/stateless/AppButton.vue';
  import AppIcon from '@/components/stateless/AppIcon.vue';
  import AppModal from '@/components/stateless/AppModal.vue';
  import AppInput from '@/components/stateless/AppInput.vue';
  import AppTabs from '@/components/stateless/AppTabs.vue';
  import AppSelectSecondary from '@/components/stateless/AppSelectSecondary.vue';
  import CategoriesList from './components/CategoriesList.vue';

  import { routesNames, router } from '@/router';
  import { ICategoryForm, TIndexedObject } from '@/types';
  import { vuex } from '@/store';
  import { SCHOOLS_PAGINATION_LIMIT } from './api-params';
  import { categoriesService } from '@/services';
  import { clearErrorField, validateField, handleSetErrors, getChangedData } from '@/core/helper-functions';
  import { categoriesFormRules } from './validation-rules';

  export default defineComponent({
    name: 'Categories',

    components: { AppButton, AppIcon, AppModal, CategoriesList, AppInput, AppSelectSecondary, AppTabs },

    setup() {
      const toast = useToast();
      const state = reactive({
        categoryForm: {
          name: '',
          iconName: { }
        } as ICategoryForm,
        originalCategoryForm: {} as ICategoryForm,
        errorMessages: { name: '', iconName: '' },
        activeTab: { value: 'active', label: 'Active' } as TIndexedObject,
      });
      const tabs: TIndexedObject[] = [
        { value: 'active', label: 'Active' },
        { value: 'archived', label: 'Archived' },
      ];
      const categoryListRef = ref(null);
      const isOpenConfirmArchiveModal = ref<boolean>(false);
      const isOpenCreateUpdateModal = ref<boolean>(false);
      const categoryId = ref<number | string>('');
      const forUpdateCategorysList = ref<boolean>(false);
      const mode = ref<'create' | 'edit'>('create');

      const iconOptions = reactive<TIndexedObject[]>([
      { iconName: 'finance', id: '1' },
      { iconName: 'career-professional', id: '2' },
      { iconName: 'health-wellbeing', id: '3' },
      { iconName: 'school-engagement', id: '4' },
      { iconName: 'community-engagement', id: '5' },
      { iconName: 'parenting', id: '6' },
      { iconName: 'other', id: '7' }
    ]);

      const { categoryForm, originalCategoryForm, errorMessages, activeTab } = toRefs(state);

      const v$ = useVuelidate(categoriesFormRules, categoryForm);

      const disabledUpdateAction = computed<boolean>(() => {
        return isEqual(
          { name: categoryForm.value.name, iconName: categoryForm.value.iconName?.iconName },
          { name: originalCategoryForm.value.name, iconName: originalCategoryForm.value.iconName });
      });

      function openConfirmationModal(id: string) {
        isOpenConfirmArchiveModal.value = true;
        categoryId.value = id;
      }

      async function openCreateUpdateModal() {
        isOpenCreateUpdateModal.value = true;

      }

      function closeCreateUpdateModal() {
        isOpenCreateUpdateModal.value = false;
        resetCategoryForm();
      }

      function discard() {
        closeConfirmationModal();
        categoryId.value = '';
      }

      function setEdit({ name, id, iconName }: { name: string, id: string, iconName: string }) {

        mode.value = 'edit';
        categoryId.value = id;
        categoryForm.value.name = name;
        categoryForm.value.iconName = { iconName, id };

        originalCategoryForm.value = { ...categoryForm.value };
      }

      function onTabChange () {
        (categoryListRef.value as any).currentPage = 1;
      }

      function resetCategoryForm() {
        categoryForm.value.name = '';
        categoryForm.value.iconName = iconOptions[0];

        errorMessages.value.name = '';
        errorMessages.value.iconName = '';

        mode.value = 'create';
      }

      async function onEdit(payload: { name: string, id: string, iconName: string }) {
        setEdit(payload);
        openCreateUpdateModal();
      }

      async function handleSave() {

        if (await v$.value.$validate()) {
          if (mode.value === 'create') {
            await createCategory();
          } else {
            await updateCategory();
          }
          closeCreateUpdateModal();
        }
        else { handleSetErrors(v$.value.$errors, errorMessages.value); }

      }

      async function createCategory() {
        vuex.setLoading(true);

        return categoriesService.createCategory({
            name: categoryForm.value.name, 
            iconName: categoryForm.value.iconName?.iconName
          })
          .then(() => {
            toast.success('Category has been created');
            vuex.setLoading(false);
            closeCreateUpdateModal();
            return (categoryListRef.value as any).fetchCategories();
          })
          .catch(({ response }: any) => {
            const { data } = response;
            toast.error(data.message);
            vuex.setLoading(false);
          });
      }

      async function updateCategory() {
        vuex.setLoading(true);

        return categoriesService.updateCategory(
          categoryId.value as string,
          getChangedData(
            { 
              name: originalCategoryForm.value.name,
              iconName: originalCategoryForm.value.iconName 
            }, 
            {
              name: categoryForm.value.name,
              iconName: categoryForm.value.iconName?.iconName
            }))
          .then(() => {
            toast.success('Category has been updated');
            vuex.setLoading(false);
            closeCreateUpdateModal();
            return (categoryListRef.value as any).fetchCategories();
          })
          .catch(({ response }: any) => {
            const { data } = response;
            toast.error(data.message);
            vuex.setLoading(false);
          });
      }

      async function archiveCategory() {
        vuex.setLoading(true);

        return categoriesService.archiveCategory(categoryId.value as string)
          .then(() => {
            toast.success('Category has been archived');
            vuex.setLoading(false);
            closeConfirmationModal();
            (categoryListRef.value as any).categoryTotal -= 1; // to avoid problem with pagination 
            nextTick(() => {

              (categoryListRef.value as any).fetchCategories();
            });
          })
          .catch(({ response }: any) => {
            const { data } = response;
            toast.error(data.message);
            vuex.setLoading(false);
          });
      }

      async function unarchiveCategory() {
        vuex.setLoading(true);

        return categoriesService.unarchiveCategory(categoryId.value as string)
          .then(() => {
            toast.success('Category has been unarchived');
            vuex.setLoading(false);
            closeConfirmationModal();
            (categoryListRef.value as any).categoryTotal -= 1; // to avoid problem with pagination 
            nextTick(() => {

              (categoryListRef.value as any).fetchCategories();
            });
          })
          .catch(({ response }: any) => {
            const { data } = response;
            toast.error(data.message);
            vuex.setLoading(false);
          });
      }

      function handleArchivation() {
        if ( activeTab.value.value === 'active') {
          archiveCategory();
        } else {
          unarchiveCategory();
        }
      }

      function closeConfirmationModal() {
        isOpenConfirmArchiveModal.value = false;
      }

      function onAddNewCategory() {
        resetCategoryForm();
        openCreateUpdateModal();
      }

      return {
        categoryListRef,
        routesNames,
        router,
        isOpenConfirmArchiveModal,
        isOpenCreateUpdateModal,
        mode,
        errorMessages,
        forUpdateCategorysList,
        iconOptions,
        categoryForm,
        tabs,
        activeTab,

        v$,
        disabledUpdateAction,

        SCHOOLS_PAGINATION_LIMIT,

        discard,
        handleArchivation,
        onEdit,
        openConfirmationModal,
        closeCreateUpdateModal,
        handleSave,
        onAddNewCategory,
        onTabChange,
        validateField: (name: string) => validateField(name, v$, errorMessages.value),
        clearErrorField: (name: string) => clearErrorField(name, errorMessages.value),
      };
    }

  });
