<template>
  <div class="bg-white rounded-5 px-50 py-35">
    <div class="mb-15">
      <div
        class="inline-flex items-center text-grey-fp-50 cursor-pointer"
        @click="router.push({ name: routesNames.adminSettings })"
      >
        <AppIcon
          name="arrow-thin-left"
          class="mr-10"
        />
        <p class="text-md">Back to Settings</p>
      </div>
    </div>

    <div class="flex items-center justify-between mb-15">
      <div class="flex items-center">
        <AppIcon
          name="burger"
          size="30"
          class="text-blue-ap-100 mr-20"
        />
        <p class="text-2xl">Categories</p>
      </div>

      <AppButton
        v-if="activeTab.value === 'active'"
        type="primary"
        class="px-30"
        icon-size="16"
        icon="plus"
        size="small"
        @click="onAddNewCategory"
      >
        Add New Category
      </AppButton>
    </div>

    <div class="flex mb-15">
      <AppTabs
        v-model="activeTab"
        custom-tab-classes="text-center"
        active-classees="bg-blue-ap-100"
        :items="tabs"
        @change="onTabChange"
      />
    </div>

    <CategoriesList
      ref="categoryListRef"
      :archived="activeTab.value !== 'active'"
      @edit="onEdit"
      @handle-archive="openConfirmationModal"
    />
  </div>

  <!-- A R C H I V E  &  U N A R C H I V E  C O N F I R M A T I O N -->
  <portal
    v-if="isOpenConfirmArchiveModal"
    to="admin"
  >
    <AppModal @cancel="discard">
      <div class="w-full max-w-430 min-w-360">
        <p class="text-dark-cl-20 text-md mb-8"> 
          {{ activeTab.value === 'active' ? 'Archive' : 'Unarchive' }} Category
        </p>
        <p class="text-grey-fp-60 mb-40">
          Are you sure you want to {{ activeTab.value === 'active' ? 'archive' : 'unarchive' }} this category?
        </p>
        <div class="flex items-center justify-end">
          <AppButton
            type="primary"
            size="mini"
            class="px-20 mr-10"
            @click="discard"
          >
            Close
          </AppButton>
          <AppButton
            size="mini"
            plain
            class="px-20"
            @click="handleArchivation"
          >
            Yes
          </AppButton>
        </div>
      </div>
    </AppModal>
  </portal>

  <!-- C R E A T E  &  U P D A T E  M O D A L -->
  <portal
    v-if="isOpenCreateUpdateModal"
    to="admin"
  >
    <AppModal @cancel="closeCreateUpdateModal">
      <div class="w-full max-w-430 min-w-320">
        <p class="text-dark-cl-20 text-md mb-28">{{ mode === 'create' ? 'Create' : 'Edit' }} category</p>

        <!-- F O R M -->
        <form @submit.prevent="handleSave">
          <AppInput
            v-model="v$.name.$model"
            name="name"
            class="mb-28"
            maxlength="100"
            :error="errorMessages.name"
            @focus="clearErrorField('name')"
          />
          <AppSelectSecondary
            v-model="v$.iconName.$model"
            :options="iconOptions"
            itemKey="id"
            class="mb-25"
          > 
            <template #selection="{ value }">
              <div class="flex items-center justify-center w-30 h-30 bg-primary rounded-full">
                <AppIcon :name="value.iconName" size="20" class="text-white" />
              </div>
            </template>

            <template #options="{ item }">
              <div class="flex items-center justify-center w-30 h-30 bg-primary rounded-full">
                <AppIcon :name="item.iconName" size="20" class="text-white" />
              </div>
            </template>
          </AppSelectSecondary>
        </form>

        <!-- A C T I O N S -->
        <div class="flex items-center justify-end">
          <AppButton
            size="mini"
            plain
            type="primary"
            class="px-20 mr-5"
            :disabled="disabledUpdateAction && mode === 'edit'"
            @click="handleSave"
          >
            {{ mode === 'create' ? 'Save' : 'Save changes' }}
          </AppButton>
          <AppButton
            size="mini"
            plain
            class="px-20 ml-5"
            @click="closeCreateUpdateModal"
          >
            Close
          </AppButton>
        </div>
      </div>
    </AppModal>
  </portal>
</template>

<script lang="ts">
  import { defineComponent, ref, reactive, toRefs, computed, nextTick } from 'vue';
  import { useToast } from "vue-toastification";
  import { useVuelidate } from "@vuelidate/core";
  import { isEqual } from 'lodash';

  import AppButton from '@/components/stateless/AppButton.vue';
  import AppIcon from '@/components/stateless/AppIcon.vue';
  import AppModal from '@/components/stateless/AppModal.vue';
  import AppInput from '@/components/stateless/AppInput.vue';
  import AppTabs from '@/components/stateless/AppTabs.vue';
  import AppSelectSecondary from '@/components/stateless/AppSelectSecondary.vue';
  import CategoriesList from './components/CategoriesList.vue';

  import { routesNames, router } from '@/router';
  import { ICategoryForm, TIndexedObject } from '@/types';
  import { vuex } from '@/store';
  import { SCHOOLS_PAGINATION_LIMIT } from './api-params';
  import { categoriesService } from '@/services';
  import { clearErrorField, validateField, handleSetErrors, getChangedData } from '@/core/helper-functions';
  import { categoriesFormRules } from './validation-rules';

  export default defineComponent({
    name: 'Categories',

    components: { AppButton, AppIcon, AppModal, CategoriesList, AppInput, AppSelectSecondary, AppTabs },

    setup() {
      const toast = useToast();
      const state = reactive({
        categoryForm: {
          name: '',
          iconName: { }
        } as ICategoryForm,
        originalCategoryForm: {} as ICategoryForm,
        errorMessages: { name: '', iconName: '' },
        activeTab: { value: 'active', label: 'Active' } as TIndexedObject,
      });
      const tabs: TIndexedObject[] = [
        { value: 'active', label: 'Active' },
        { value: 'archived', label: 'Archived' },
      ];
      const categoryListRef = ref(null);
      const isOpenConfirmArchiveModal = ref<boolean>(false);
      const isOpenCreateUpdateModal = ref<boolean>(false);
      const categoryId = ref<number | string>('');
      const forUpdateCategorysList = ref<boolean>(false);
      const mode = ref<'create' | 'edit'>('create');

      const iconOptions = reactive<TIndexedObject[]>([
      { iconName: 'finance', id: '1' },
      { iconName: 'career-professional', id: '2' },
      { iconName: 'health-wellbeing', id: '3' },
      { iconName: 'school-engagement', id: '4' },
      { iconName: 'community-engagement', id: '5' },
      { iconName: 'parenting', id: '6' },
      { iconName: 'other', id: '7' }
    ]);

      const { categoryForm, originalCategoryForm, errorMessages, activeTab } = toRefs(state);

      const v$ = useVuelidate(categoriesFormRules, categoryForm);

      const disabledUpdateAction = computed<boolean>(() => {
        return isEqual(
          { name: categoryForm.value.name, iconName: categoryForm.value.iconName?.iconName },
          { name: originalCategoryForm.value.name, iconName: originalCategoryForm.value.iconName });
      });

      function openConfirmationModal(id: string) {
        isOpenConfirmArchiveModal.value = true;
        categoryId.value = id;
      }

      async function openCreateUpdateModal() {
        isOpenCreateUpdateModal.value = true;

      }

      function closeCreateUpdateModal() {
        isOpenCreateUpdateModal.value = false;
        resetCategoryForm();
      }

      function discard() {
        closeConfirmationModal();
        categoryId.value = '';
      }

      function setEdit({ name, id, iconName }: { name: string, id: string, iconName: string }) {

        mode.value = 'edit';
        categoryId.value = id;
        categoryForm.value.name = name;
        categoryForm.value.iconName = { iconName, id };

        originalCategoryForm.value = { ...categoryForm.value };
      }

      function onTabChange () {
        (categoryListRef.value as any).currentPage = 1;
      }

      function resetCategoryForm() {
        categoryForm.value.name = '';
        categoryForm.value.iconName = iconOptions[0];

        errorMessages.value.name = '';
        errorMessages.value.iconName = '';

        mode.value = 'create';
      }

      async function onEdit(payload: { name: string, id: string, iconName: string }) {
        setEdit(payload);
        openCreateUpdateModal();
      }

      async function handleSave() {

        if (await v$.value.$validate()) {
          if (mode.value === 'create') {
            await createCategory();
          } else {
            await updateCategory();
          }
          closeCreateUpdateModal();
        }
        else { handleSetErrors(v$.value.$errors, errorMessages.value); }

      }

      async function createCategory() {
        vuex.setLoading(true);

        return categoriesService.createCategory({
            name: categoryForm.value.name, 
            iconName: categoryForm.value.iconName?.iconName
          })
          .then(() => {
            toast.success('Category has been created');
            vuex.setLoading(false);
            closeCreateUpdateModal();
            return (categoryListRef.value as any).fetchCategories();
          })
          .catch(({ response }: any) => {
            const { data } = response;
            toast.error(data.message);
            vuex.setLoading(false);
          });
      }

      async function updateCategory() {
        vuex.setLoading(true);

        return categoriesService.updateCategory(
          categoryId.value as string,
          getChangedData(
            { 
              name: originalCategoryForm.value.name,
              iconName: originalCategoryForm.value.iconName 
            }, 
            {
              name: categoryForm.value.name,
              iconName: categoryForm.value.iconName?.iconName
            }))
          .then(() => {
            toast.success('Category has been updated');
            vuex.setLoading(false);
            closeCreateUpdateModal();
            return (categoryListRef.value as any).fetchCategories();
          })
          .catch(({ response }: any) => {
            const { data } = response;
            toast.error(data.message);
            vuex.setLoading(false);
          });
      }

      async function archiveCategory() {
        vuex.setLoading(true);

        return categoriesService.archiveCategory(categoryId.value as string)
          .then(() => {
            toast.success('Category has been archived');
            vuex.setLoading(false);
            closeConfirmationModal();
            (categoryListRef.value as any).categoryTotal -= 1; // to avoid problem with pagination 
            nextTick(() => {

              (categoryListRef.value as any).fetchCategories();
            });
          })
          .catch(({ response }: any) => {
            const { data } = response;
            toast.error(data.message);
            vuex.setLoading(false);
          });
      }

      async function unarchiveCategory() {
        vuex.setLoading(true);

        return categoriesService.unarchiveCategory(categoryId.value as string)
          .then(() => {
            toast.success('Category has been unarchived');
            vuex.setLoading(false);
            closeConfirmationModal();
            (categoryListRef.value as any).categoryTotal -= 1; // to avoid problem with pagination 
            nextTick(() => {

              (categoryListRef.value as any).fetchCategories();
            });
          })
          .catch(({ response }: any) => {
            const { data } = response;
            toast.error(data.message);
            vuex.setLoading(false);
          });
      }

      function handleArchivation() {
        if ( activeTab.value.value === 'active') {
          archiveCategory();
        } else {
          unarchiveCategory();
        }
      }

      function closeConfirmationModal() {
        isOpenConfirmArchiveModal.value = false;
      }

      function onAddNewCategory() {
        resetCategoryForm();
        openCreateUpdateModal();
      }

      return {
        categoryListRef,
        routesNames,
        router,
        isOpenConfirmArchiveModal,
        isOpenCreateUpdateModal,
        mode,
        errorMessages,
        forUpdateCategorysList,
        iconOptions,
        categoryForm,
        tabs,
        activeTab,

        v$,
        disabledUpdateAction,

        SCHOOLS_PAGINATION_LIMIT,

        discard,
        handleArchivation,
        onEdit,
        openConfirmationModal,
        closeCreateUpdateModal,
        handleSave,
        onAddNewCategory,
        onTabChange,
        validateField: (name: string) => validateField(name, v$, errorMessages.value),
        clearErrorField: (name: string) => clearErrorField(name, errorMessages.value),
      };
    }

  });
</script>